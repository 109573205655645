import React from 'react'
import './Links.scss'

const Links = () => {
    return(
        <div className="links-container">
            <div className="email">
                <a className="email-text" href="mailto: erwang1151@gmail.com" target="_blank" rel="noreferrer">erwang1151@gmail.com</a>
                <div className="line"></div>
            </div>
            <div className="social-links">
            <a href="https://github.com/ewang0" target="_blank" rel="noreferrer"><img className="github-icon" src="../github-icon.svg" alt="github"/></a>
                <a href="https://www.linkedin.com/in/ericwang20/" target="_blank" rel="noreferrer"><img className="linkedin-icon" src="../linkedin-icon.svg" alt="linkedin"/></a>
                <a href="https://ericwang.squarespace.com" target="_blank" rel="noreferrer"><img className="linkedin-icon" src="../paint-brush.png" alt="art website"/></a>
                <div className="line"></div>
            </div>
        </div>
    )
}

export default Links;