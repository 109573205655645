import React from 'react';
import './FeaturedProjectCard.scss';
import ProjectCard from './ProjectCard';

const FeaturedProjectCard = ({ orientation, type, title, description, skills, repoLink, deployedLink, imagePath, alt} ) => {
    return(
        <article className={`card-container ${orientation}-card`}>
            { orientation === "image-left" ? 
                <a className={`mockup-link ${orientation}-mockup`} href={deployedLink} target="_blank" rel="noreferrer">
                    <img className={`mockup ${orientation}-mockup`} src={imagePath} alt={alt}/>
                </a>
            : null }

            <ProjectCard 
                orientation={orientation}
                type={type}
                title={title}
                description={description}
                skills={skills}
                repoLink={repoLink}
                deployedLink={deployedLink}
                imagePath={imagePath}
            />

            { orientation === "image-right" ? 
                <a className={`mockup-link ${orientation}-mockup`} href={deployedLink} target="_blank" rel="noreferrer">
                    <img className={`mockup ${orientation}-mockup`} src={imagePath} alt={alt}/>
                </a>
            : null }
        </article>
    )
}

export default FeaturedProjectCard;