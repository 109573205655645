import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'
import BurgerMenu from './BurgerMenu';
import './Nav.scss';

const Nav = () => {
    const [currentScrollPos, setCurrentScrollPos] = useState(window.pageYOffset);
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    const handleScroll = () => {
        const nav = document.querySelector('nav');

        if(burgerMenuOpen) {
            setBurgerMenuOpen(false);
        }

        if(window.scrollY === 0) {
            nav.style.boxShadow = "0 2px 15px 0 rgba(126, 109, 109, 0)"
            nav.style.backdropFilter = "blur( 0px )";
            nav.style.background = "rgba( 255, 238, 221, 0 )"

        } else if (window.scrollY > 92) {
            nav.style.boxShadow = "0 2px 15px 0 rgba(126, 109, 109, 0.1)"
            nav.style.backdropFilter = "blur( 7px )";
            nav.style.background = "rgba( 255, 238, 221, 0.75 )"
            nav.style.borderBottom = "1px solid rgba( 255, 255, 255, 0.18 )"
        }

        if(window.pageYOffset > currentScrollPos && window.pageYOffset > 0) {
            nav.style.marginTop = "-150px"
        }
        if(window.pageYOffset < currentScrollPos-70) {
            nav.style.marginTop = "0px"
        }
        
        setCurrentScrollPos(window.pageYOffset)
    }

    const toggleBurgerMenu = (bool) => {
        setBurgerMenuOpen(bool)
        if(bool) {
            const nav = document.querySelector('nav');
            nav.style.boxShadow = "0 2px 15px 0 rgba(126, 109, 109, 0)"
            nav.style.backdropFilter = "blur( 0px )";
            nav.style.background = "rgba( 255, 238, 221, 0 )"
        } 
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        const nav = document.querySelector('nav');
        if(isMobile) {
            nav.style.boxShadow = "0 2px 15px 0 rgba(126, 109, 109, 0.1)"
            nav.style.backdropFilter = "blur( 7px )";
            nav.style.background = "rgba( 255, 238, 221, 0.75 )"
            nav.style.borderBottom = "1px solid rgba( 255, 255, 255, 0.18 )"
        } else if (window.scrollY === 0) {
            nav.style.boxShadow = "0 2px 15px 0 rgba(126, 109, 109, 0)"
            nav.style.backdropFilter = "blur( 0px )";
            nav.style.background = "rgba( 255, 238, 221, 0 )"
        }
    })

    return(
        <nav>
            <a href="#home" className="nav-link logo" to="/">E</a>
            { isMobile ? 
                !burgerMenuOpen ? <button className="hamburger-menu-icon" onClick={() => toggleBurgerMenu(true)}><i className="fas fa-bars fa-3x"></i></button> : <BurgerMenu toggleBurgerMenu={toggleBurgerMenu}/>
                    : <ol className="nav-items">
                        <li>
                            <a href="#about">about</a>
                        </li>
                        {/* <li>
                            <a href="#testimonials">testimonials</a>
                        </li> */}
                        <li>
                            <a href="#projects">projects</a>
                        </li>
                        <li>
                            <a href="#contact">contact</a>
                        </li>
                        <li>
                            <a href="website-resume.pdf" target="_blank" rel="noreferrer">resume</a>
                        </li>
                        {/* <li>
                            <a href="https://ericwang.squarespace.com" target="_blank" rel="noreferrer">art</a>
                        </li> */}
                    </ol>           
            }
        </nav>
    )
}

export default Nav;