const featuredProjects = [
    {
        title:'Prepared',
        type: 'DESIGN + CODE',
        orientation: 'image-right',
        description: 'New brand identity and marketing site development for Prepared, an assistive AI startup supporting 911 telecommunicators. The website is responsive, cross-browser compatible, optimized for search engines, and integrated with analytics and feedback tools such as Hubspot.',
        skills: ['Webflow', 'Hubspot', 'SEO Optimization', 'Brand Identity', 'Motion Design'],
        repoLink: 'https://www.prepared911.com/',
        deployedLink: 'https://www.prepared911.com/',
        imagePath: '../prepared-mockup.png',
        alt:'Link to Prepared911 project'
    }, 
    {
        title:'FOMO.ai',
        type: 'DESIGN + CODE',
        orientation: 'image-left',
        description: 'New brand identity, design system, and front-end development for FOMO.ai, a SAAS startup for automated marketing tools.',
        skills: ['Next.js', 'Vercel', 'TypeScript', 'Tailwind CSS', 'Shadcn/ui', 'AWS Amplify', 'OpenAI'],
        repoLink: 'https://app.fomo.ai/',
        deployedLink: 'https://app.fomo.ai/',
        imagePath: '../fomo-mockup.png',
        alt:'Link to FOMO.ai project'
    }, 
    {
        title:'AI Positivity Journal',
        type: 'DESIGN + CODE',
        orientation: 'image-right',
        description: 'AI Positivity Journal streams data from OpenAI’s Chat Completions API to provide reassurance, guidance, and perspective on any difficult situation we humans might face. Hear AI-generated quotes and guidance from philosophers, artists, writers, and other important historical figures.',
        skills: ['Next.js', 'Vercel', 'TypeScript', 'Tailwind CSS', 'OpenAI Stream', 'Planetscale DB', 'Vercel AI SDK'],
        repoLink: 'https://ai-positivity-journal.vercel.app/',
        deployedLink: 'https://ai-positivity-journal.vercel.app/',
        imagePath: '../ai-positivity-journal-mockup.png',
        alt:'Link to AI Positivity Journal project'
    }, 
    {
        title:'AI Naming Tool',
        type: 'DESIGN + CODE',
        orientation: 'image-left',
        description: 'A full-stack, AI naming tool built with a Python back end and a React front end. Generate a list of literal, phrasal, and compound descriptive names using OpenAI\'s Chat Completions API and one-click generate a moodboard of images using Stable Diffusion API.',
        skills: ['React', 'Python', 'Flask', 'OpenAI API', 'Stable Diffusion API', 'styled components'],
        repoLink: 'https://lip-play-ai.netlify.app/',
        deployedLink: 'https://lip-play-ai.netlify.app/',
        imagePath: '../namezap-mockup.png',
        alt:'Link to AI Naming Tool project'
    }, 
    {
        title:'AI "PLAY" Microsite',
        type: 'DESIGN + CODE',
        orientation: 'image-right',
        description: 'An interactive, journalistic account of Lippincott\'s internal experimentation with artificial intelligence. The site features richly animated articles using Framer Motion and embedded 3D models using Three.js—stay tuned for the upcoming public launch.',
        skills: ['React', 'TypeScript', 'Three.js', 'Framer Motion', 'styled components'],
        repoLink: 'https://lippincott.ai/',
        deployedLink: 'https://lippincott.ai/',
        imagePath: '../play-mockup.png',
        alt:'Link to AI PLAY project'
    }, 
    {
        title:'METVIRTUAL',
        type: 'DESIGN + CODE',
        orientation: 'image-left',
        description: 'A 6-day solo project built with JavaScript and React. Users can search, sort, and filter over 470,000 objects in the Metropolitan Museum of Art\'s collection. Image and object data is fetched from the Metropolitan Museum of Art Collection API.',
        skills: ['JavaScript', 'React', 'HTML', 'CSS', 'Cypress', 'React Router', 'Figma'],
        repoLink: 'https://github.com/ewang0/virtual-museum',
        deployedLink: 'https://metvirtual.netlify.app/',
        imagePath: '../METVIRTUAL-mockup.png',
        alt:'Link to METVIRTUAL project'
    }, 
    // {
    //     title:'Cravr',
    //     type: 'CODE',
    //     orientation: 'image-right',
    //     description: 'A food recommendations web app built with React and TypeScript. Users can answer a series of questions and receive food recommmendations to find out exactly what they\'re hungry for. Food and recipe data was fetched from the Spoonacular API.',
    //     skills: ['TypeScript', 'React', 'HTML', 'CSS', 'Sass', 'Cypress', 'Figma'],
    //     repoLink: 'https://github.com/ewang0/cravr',
    //     deployedLink: 'https://cravr.netlify.app',
    //     imagePath: '../cravr-mockup.png',
    //     alt:'Link to Cravr project'
    // },
    // {
    //     title:'Rancid Tomatillos',
    //     type: 'CODE',
    //     orientation: 'image-left',
    //     description: 'A movie ratings website built with React, populated with movie data and images from an API. Features include search, sorting by movie genre, and grid/banner-style display of movies.',
    //     skills: ['JavaScript', 'React', 'HTML', 'CSS', 'Cypress', 'React Router', 'Figma'],
    //     repoLink: 'https://www.github.com/ewang0/rancid_tomatillos',
    //     deployedLink: 'https://ewang0.github.io/rancid_tomatillos/',
    //     imagePath: '../rancid-mockup-1.png',
    //     alt:'Link to Rancid Tomatillos project'
    // }, 

    // {
    //     title:'Travel Tracker',
    //     type: 'CODE',
    //     orientation: 'image-right',
    //     description: 'A travel tracking website built with vanilla JavaScript, using travel data from an API. Users can login, add trips, and see total trip-related costs based on inputs.',
    //     skills: ['JavaScript','HTML', 'CSS', 'Sass', 'Mocha', 'Chai', 'Figma'],
    //     repoLink: 'https://github.com/ewang0/travel-tracker',
    //     deployedLink: 'https://github.com/ewang0/travel-tracker',
    //     imagePath: '../travel-tracker-mockup.png',
    //     alt:'Link to Travel Tracker project'
    // },
    // {
    //     title:'FitLit Exercise Tracker',
    //     type: 'CODE',
    //     orientation: 'image-right',
    //     description: 'An exercise tracker displaying activity, hydration, sleep, and personal data, built with vanilla JavaScript. Fictional user data was fetched from a provided API.',
    //     skills: ['JavaScript','HTML', 'CSS', 'Sass', 'Mocha', 'Chai', 'Figma'],
    //     repoLink: 'https://github.com/ewang0/fitlit',
    //     deployedLink: 'https://github.com/ewang0/fitlit',
    //     imagePath: '../fitlit-mockup.png',
    //     alt:'Link to Fitlit project'
    // },
    // {
    //     title:'Space-Attack-Toe Game',
    //     type: 'CODE',
    //     orientation: 'image-right',
    //     description: 'A Tic-Tac-Toe game built with vanilla JavaScript. Players can place tokens and track number of wins, which persist on page refresh.',
    //     skills: ['JavaScript','HTML', 'CSS','Mocha', 'Chai', 'Figma'],
    //     repoLink: 'https://github.com/ewang0/tic-tac-toe',
    //     deployedLink: 'https://ewang0.github.io/tic-tac-toe/',
    //     imagePath: '../space-attack-toe-mockup.png',
    //     alt:'Link to Space Attack Toe project'
    // }
]

const smallProjects = [
    {
        title:'PeerTECH Health App',
        type: 'WEB UX DESIGN',
        orientation: 'small',
        description: 'A peer-to-peer mental health support web app for healthcare organizations. Healthcare administrators can view user metrics, and peer support specialists can use the app to assist patients.',
        skills: [],
        repoLink: 'https://ewang1151.wixsite.com/mysite/peertech-health',
        deployedLink: 'https://ewang1151.wixsite.com/mysite/peertech-health',
        imagePath: '../peertech-mockup.png',
        alt:'Link to PeerTECH UX design project'
    },
    {
        title:'Pest Prediction Tool',
        type: 'WEB UX DESIGN',
        orientation: 'small',
        description: 'A tool to help predict the probability of invasive species in the United States. Park administrators, forestry professionals, educators, and students can input insect data and determine risk.',
        skills: [],
        repoLink: 'https://ewang1151.wixsite.com/mysite/pest-predict',
        deployedLink: 'https://ewang1151.wixsite.com/mysite/pest-predict',
        imagePath: '../pest-predict-mockup.png',
        alt:'Link to Pest Prediction UX design project'
    },
    {
        title:'Unmasked Mental Health App',
        type: 'MOBILE UX DESIGN',
        orientation: 'small',
        description: 'A mental health support message board built for college students. Anonymous users can post, chat, and be directed to mental health resources on campus and beyond.',
        skills: [],
        repoLink: 'https://ewang1151.wixsite.com/mysite/unmasked',
        deployedLink: 'https://ewang1151.wixsite.com/mysite/unmasked',
        imagePath: '../unmasked-mockup.png',
        alt:'Link to Unmasked UX design project'
    },
    {
        title:'Zamani Project Website',
        type: 'WEB UX DESIGN',
        orientation: 'small',
        description: 'A static website for the Zamani Project, a non-profit focused on preserving historical sites in Africa using 3D modeling and other spatial data. Users can see basic info, publications, and collected data.',
        skills: [],
        repoLink: 'https://ewang1151.wixsite.com/mysite/the-zamani-project',
        deployedLink: 'https://ewang1151.wixsite.com/mysite/the-zamani-project',
        imagePath: '../zamani-mockup.png',
        alt:'Link to Zamani UX design project'
    }
]

export { featuredProjects, smallProjects };