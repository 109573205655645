import React from 'react';
import './Hero.scss';

const Hero = () => {
    return (
        <section id="home" className="hero-section-container">
            <div className="hero-section-content">
                <h1 className="hero-h1">WELCOME</h1>
                <h2 className="hero-h2">I'm Eric Wang, a front-end developer and designer.</h2>
                <p className="hero-p">I design and build accessible, human-centered products for the web. Currently, I’m a freelancer based out of <b>New York City</b>.</p>
                <a href="#projects"><button className="view-projects-button">View Projects</button></a>
            </div>
        </section>
    )
}

export default Hero;