import React from 'react';
import "./Contact.scss";

const Contact = () => {
    return (
        <section id="contact" className="contact-section-container">
            <div className="contact-section-content">
                <h1>03</h1>
                <h2>Get in Touch</h2>
                <p>My inbox is always open! If you have any questions about me or my experience, or if you're looking to collaborate on a project, please don't hesitate to reach out.</p>
                <a href="mailto: erwang1151@gmail.com" target="_blank" rel="noreferrer"><button>Say Hi</button></a>
            </div>
        </section>
    )
}

export default Contact;