import React from 'react';
import './BurgerMenu.scss'

const BurgerMenu = ({ toggleBurgerMenu }) => {
    return(
        <div className="burger-menu-container">
            <button className="hamburger-menu-icon exit" onClick={() => toggleBurgerMenu(false)}><img src="cross-sign.png" alt="exit"/></button>
            <ol className="nav-items burger">
                <li>
                    <a href="#about">about</a>
                </li>
                {/* <li>
                    <a href="#testimonials">testimonials</a>
                </li> */}
                <li>
                    <a href="#projects">projects</a>
                </li>
                <li>
                    <a href="#contact">contact</a>
                </li>
                <li>
                    <a href="website-resume.pdf" target="_blank" rel="noreferrer">resume</a>
                </li>
                {/* <li>
                    <a href="https://ericwang.squarespace.com" target="_blank" rel="noreferrer">art</a>
                </li> */}
            </ol>    
        </div>
    )
}

export default BurgerMenu;