import React from 'react';
import FeaturedProjectCard from './FeaturedProjectCard';
import './Projects.scss';
// import ProjectCard from './ProjectCard';
import { featuredProjects } from './Content.js';

const Projects = () => {
    const featured = featuredProjects.map((project) => {
        return (
            <FeaturedProjectCard 
                orientation={project.orientation}
                type={project.type}
                title={project.title}
                description={project.description}
                skills={project.skills}
                repoLink={project.repoLink}
                deployedLink={project.deployedLink}
                imagePath={project.imagePath}
                alt={project.alt}
            />
        )
    })

    // const small = smallProjects.map((project) => {
    //     return (
    //         <ProjectCard 
    //             orientation={project.orientation}
    //             type={project.type}
    //             title={project.title}
    //             description={project.description}
    //             skills={project.skills}
    //             repoLink={project.repoLink}
    //             deployedLink={project.deployedLink}
    //             imagePath={project.imagePath}
    //             alt={project.alt}
    //         />
    //     )
    // })
    
    return (
        <section id="projects"className="projects-section">
            <div className="section-number">02</div>
            <h2 className="section-title">Projects</h2>
            <div className="featured-projects-container">
                {featured}
            </div>
            {/* <section className="other-projects">
                <h3 className="section-title small-title">Design Case Studies</h3>
                <div className="other-projects-container">
                    {small}
                </div>
            </section> */}
        </section>
    )
}

export default Projects;