import React from 'react';
import './ProjectCard.scss';

const ProjectCard = ({ orientation, type, title, description, skills, repoLink, deployedLink, imagePath, alt }) => {
    const badges = skills.map((skill) => {
        return (
            <img alt={skill} src={`https://img.shields.io/badge/${skill}%20-%23292525.svg?&style=for-the-badge`}/>
        )
    })

    return(
        <article className={`project-description-container ${orientation}`}>
            <div className="project-description-content">
                <p className="project-type">{type}</p>
                <a href={repoLink} target="_blank" rel="noreferrer" className="project-title">{title}</a>
                <p className="project-description">{description}</p>
            <a className="view-project" href={deployedLink} target="_blank" rel="noreferrer">View project<img src="././arrow.svg" alt={`arrow icon and link to ${title} project`}/></a>
                <div className="project-card-badges">
                    {badges}
                </div>
                { orientation === "small" ?
                    <a className="small-mockup-wrapper" href={deployedLink} target="_blank" rel="noreferrer">
                        <img className={`mockup ${orientation}-mockup`} src={imagePath} alt={alt}/>
                    </a>
                : null }
            </div>
        </article>
    )
}

export default ProjectCard;
