import './App.scss';
import Nav from './Nav.js';
import Links from './Links.js';
import Hero from './Hero.js';
import About from './About.js';
import Projects from './Projects.js'
import Contact from './Contact.js'
// import Testimonials from './Testimonials';

function App() {
  return (
    <main>
      <Nav />
      <Links />
      <Hero />
      <About />
      {/* <Testimonials /> */}
      <Projects />
      <Contact />
    </main>
  );
}

export default App;
