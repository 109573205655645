import React from 'react';
import './About.scss';

const About = () => {
    return (
        <section id="about" className="about-section-container">
            <div className="about-section-content">
                <div className="about-text">
                    <h2><div className="section-number">01</div>About</h2>
                    <p>Hi there—I’m a freelance designer and developer based out of New York City. Graduating with a B.A. from Dartmouth College in 2020, I went on to work professionally as a Front End Engineer for both <a href="https://advertising.amazon.com/" target="_blank" rel="noreferrer">Amazon</a> and <a href="https://lippincott.com/" target="_blank" rel="noreferrer">Lippincott</a> before transitioning to freelance work full time. Currently, I take on my own freelance projects, and have teamed up with a designer to form <a href="https://www.twopixel.studio/" target="_blank" rel="noreferrer">Two Pixel Studio</a>, a full-service design and development agency.</p>
                    <p>When I’m not coding, you can find me playing tennis, working on my paintings, or eating something delicious in NYC.</p>
                    <div className="badges">
                        <img alt="React" src="https://img.shields.io/badge/react%20-%233F3D3D.svg?&style=for-the-badge&logo=react&logoColor=%236FE0D4"/>
                        <img alt="Next"src="https://img.shields.io/badge/Next.js%20-%233F3D3D.svg?&style=for-the-badge&logo=next.js&logoColor=white"/>
                        <img alt="TypeScript" src="https://img.shields.io/badge/typescript%20-%233F3D3D.svg?&style=for-the-badge&logo=typescript&logoColor=%238BD3ED"/>
                        <img alt="JavaScript" src="https://img.shields.io/badge/javascript%20-%233F3D3D.svg?&style=for-the-badge&logo=javascript&logoColor=%23FCD066"/>
                        <img alt="Tailwind" src="https://img.shields.io/badge/Tailwind CSS%20-3F3D3D.svg?&style=for-the-badge&logo=TailwindCSS&logoColor=38BDF9"/>
                        <img alt="styled-components" src="https://img.shields.io/badge/styled components%20-3F3D3D.svg?&style=for-the-badge&logo=styled-components&logoColor=EE91B9"/>
                        <img alt="Vercel" src="https://img.shields.io/badge/Vercel%20-3F3D3D.svg?&style=for-the-badge&logo=Vercel&logoColor=white"/>
                        <img alt="three.js" src="https://img.shields.io/badge/three.js%20-%233F3D3D.svg?&style=for-the-badge&logo=three.js&logoColor=38F94B"/>
                        <img alt="Framer Motion" src="https://img.shields.io/badge/-Framer Motion-3F3D3D?logo=framer&logoColor=BFECCE&style=for-the-badge"/>
                        {/* <img alt="Open AI" src="https://img.shields.io/badge/Open AI%20-%233F3D3D.svg?&style=for-the-badge&logo=OpenAI&logoColor=9E63FF"/> */}
                        <img alt="Figma" src="https://img.shields.io/badge/Figma-3F3D3D?style=for-the-badge&logo=figma&logoColor=D2ABE7"/>
                    </div>
                </div>
                <div className="styled-profile">
                    <div className='border'></div>
                    <img className='profile-pic' src="./eric-profile.jpeg" alt="Profile of Eric Wang"></img>
                </div>
            </div>
        </section>
    )
}

export default About;